<template>
    <div>
        <div class="header_wrap"
            v-if="!$route.path.includes('sessions') && !$route.path.includes('register') && !$route.path.includes('set-sec-password')"
            :class="{ 'pb-4': $route.path.includes('homepage') }">

            <div class="content background-image">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="logo">
                        <router-link tag="a" to="/web/homepage" class="">  
                            <img :src="require('./assets/images/logo/logo.png')" alt="" width="160px">
                        </router-link>
                    </div>
                    <div class="d-flex justify-content-around align-items-center">
                        <div class="ml-4" style="">
                            <b-dropdown id="dropdown-lang" right variant="link" toggle-class="my-dropdown">

                                <template #button-content>
                                    <!-- <img :src="require(`./assets/images/flag/${$i18n.locale}.png`)" alt="" width="20px"> -->
                                    <span v-if="$i18n.locale == 'en'">
                                        <img :src="require('./assets/images/logo/en.png')" alt="" width="30px">
                                    </span>
                                    <span v-else-if="$i18n.locale == 'zh'">
                                        <img :src="require('./assets/images/logo/zh.png')" alt="" width="30px">
                                    </span>
                                    <!-- <span class="text-primary text-16 font-weight-bold text-uppercase">{{ $i18n.locale }}</span> -->
                                </template>
                                <b-dropdown-item href="#" @click="$root.changeLan('en')">English</b-dropdown-item>
                                <b-dropdown-item href="#" @click="$root.changeLan('zh')">中文</b-dropdown-item>
                                <!-- <b-dropdown-item href="#" @click="$root.changeLan('in')">Indon</b-dropdown-item> -->
                            </b-dropdown>
                        </div>
                        <div id="tooltip-button-1" class="text-20 ml-4 text-primary position-relative" style="display: inline-block;" @click="show = !show">
                            <img :src="require('./assets/images/logo/bell.png')" alt="" width="30px" class="position-relative">
                            <span v-if="this.$root.unRead > 0" class="notification-badge">{{ this.$root.unRead }}</span>
                        </div>

                        <b-tooltip :show.sync="show" target="tooltip-button-1" placement="bottom">
                            <div class="title-news text-left" >
                                {{ $t('news') }}
                            </div>
                            <div class="title-content justify-content-between d-flex" @click="openNews(item)" v-for="item in $root.newsList.slice(0, 4)" :key="item.id">
                                <img :src="item.public_path" alt="" height="48px" width="48px">
                                <div class="content ml-2 text-left">
                                    <div class="text-14 font-weight-semibold mb-1 text-ellipsis" style="max-width: 340px;">{{ item.title }}</div>
                                    <div class="text-secondary mb-1 max-line-2 text-14">{{ item.description }}</div>
                                    <div class="text-secondary text-12">{{ item.updated_at }}</div>

                                </div>
                                <div v-if="item.is_read == 0">
                                    <b-icon icon="dot" class="text-24 p-0 text-danger text-right" ></b-icon>
                                </div>

                            </div>
                            <footer v-if="$root.newsList.length > 4">
                                <div class="py-2 title-news" @click="openMore">{{$t('more')}}</div>
                            </footer>
                        </b-tooltip>
                        <div class="text-20 text-primary ml-4" v-b-toggle.sidebar-right>
                            <img :src="require('./assets/images/logo/menu_icon.png')" alt="" width="30px">
                        </div>

                    </div>
                </div>

                <!-- <div class="justify-content align-items-end mt-4 mb-2 d-flex"
                    v-if="$route.path.includes('homepage')">
                    <h3 class="mt-3 text-white text-20 font-weight-semibold">{{ $t('welcome') }} {{ $root.username }}</h3>
                </div> -->

            </div>
        </div>
        <div class="d-flex justify-content align-items-end d-sm-none"
            v-if="!$route.path.includes('sessions') && !$route.path.includes('set-sec-password') && !$route.path.includes('register') && !$route.path.includes('homepage')">
            <!-- <h3 class="my-3 mx-4 text-20 font-weight-semibold">{{ $t($parent.title) }}</h3> -->
        </div>
        <b-sidebar id="sidebar-right" title="Sidebar" no-header right shadow backdrop>
            <div class="profile-outer-box mb-4">
                <div class=""></div>
                <div class="profile-box">
                    <span class="text-64">
                        <i class="text-primary fa-regular fa-circle-user"></i>
                    </span>
                    <!-- <img :src="require('./assets/images/xlogo_signin/top_xlogo.png')" alt="" width="100%"> -->
                    <h4 class="text-18 mb-4 mt-2 text-center text-white">{{ $root.username }}</h4>
                    <!-- <b-button variant="primary" @click="$router.push('/web/trade')">{{ $t('start_trading') }}</b-button> -->

                </div>
            </div>

            <div class="text-left mb-4">
                <router-link tag="a" to="/web/profile?type=1" class="">
                    <span class="nav-text">
                        {{ $t("account_info") }}
                    </span>
                </router-link>
            </div>

            <div class="text-left mb-4">
                <router-link tag="a" to="/web/profile?type=2" class="">
                    <span class="nav-text">
                        {{ $t("password_setting") }}
                    </span>
                </router-link>
            </div>

            <div class="text-left mb-4">
                <router-link tag="a" to="/web/profile?type=3" class="">
                    <span class="nav-text">
                        {{ $t("kyc") }}
                    </span>
                </router-link>
            </div>

            <div class="text-left mb-4">
                <router-link tag="a" to="/web/profile?type=4" class="">
                    <span class="nav-text">
                        {{ $t("deposit") }}
                    </span>
                </router-link>
            </div>

            <div class="text-left mb-4">
                <router-link tag="a" to="/web/profile?type=5" class="">
                    <span class="nav-text">
                        {{ $t("withdrawal") }}
                    </span>
                </router-link>
            </div>
            <div class="text-left mb-4">
                <router-link tag="a" to="/web/wallet" class="">
                    <span class="nav-text">
                        {{ $t("transaction") }}
                    </span>
                </router-link>
            </div>
            <div class="flex-grow-1"></div>

            <div class="text-left mb-4">
                <span class="nav-text logout" @click="$parent.logout">
                    {{ $t("logout") }}
                </span>
            </div>
        </b-sidebar>
    </div>
</template>
<script>
import { readNews } from "@/system/request/api/api";
import { handleError } from "@/system/request/handleRes";
export default {
    data(){
        return {
            show: false,
        }
    },
    computed: {
        isNewsRoute() {
            return this.$route.path === '/web/news';
        }
    },
    methods: {
        openNews(item){
            if(item.is_read == 0){

            let formData = new FormData();
            formData.append("news_id", item.id);

            var self = this;
            self.$root.isLoading = true;

            var result = readNews(formData);

            result
                .then(function (value) {
                    if (value.data.error_code == 0 ) {
                        // self.$root.makeToast("success", self.$t(value.data.message), 'success');
                        self.show = !self.show;
                        self.$root.newsItem = item;

                    } else {
                        self.$root.makeToast("danger", self.$t(value.data.message), 'danger');
                    }
                    self.show = !self.show;
                    self.$root.getNewsList();
                    self.$root.isLoading = false;
                })
                .catch(function (error) {
                    console.log(error);
                    self.$root.makeToast("warning", self.$t(handleError(error)), 'danger');
                    self.$root.isLoading = false;
                });
            } else 
            this.show = !this.show;
            this.$root.newsItem = item;
            this.$bvModal.show('modal-news');

        },
        openMore(){
            if (this.isNewsRoute) return;

            this.show = false;  
            this.$router.push('/web/news');  
        }
    }

}
</script>

<style scoped>
#sidebar-right.b-sidebar {
    background-color: black !important;
}
</style>